<template>
    <div class="coupons-index-page">
        <LayoutBreadcrumbs :layers="breadcrumbs" text-color="text-gray-900" />

        <div class="coupons-index-page__slider">
            <h1 class="my-3 text-2xl font-semibold text-gray-800">
                {{ $lang.pages.ofertas.featured_offers }}
            </h1>
            <WidgetSlider class="h-[243px] lg:h-[287px]">
                <WidgetSliderItem v-for="(item, key) in info.page.data.featured_offers" :key="key">
                    <DCardVertical :info="item" :lazy-image="key > 1" />
                </WidgetSliderItem>
            </WidgetSlider>
        </div>

        <section class="coupons-index-page__featured-brands">
            <h3>{{ $lang.pages.ofertas.popular_brands }}</h3>
            <WidgetStoresGallery :data="info.page.data.featured_stores" />
        </section>

        <section class="coupons-index-page__content">
            <main class="coupons-index-page__main">
                <MiscTabs :info="tabsData" class="my-3" />

                <template v-if="tabsData.show === 0">
                    <ul class="space-y-3">
                        <li v-for="(item, key) in info.page.data.popular.data" :key="item.title + item.id">
                            <DCardMain :info="item" />
                        </li>
                    </ul>
                </template>

                <template v-if="tabsData.show === 1">
                    <ul class="space-y-3">
                        <li v-for="item in info.page.data.latest.data" :key="item.title + item.id">
                            <DCardMain :info="item" />
                        </li>
                    </ul>
                </template>

                <template v-if="tabsData.show === 2">
                    <ul class="space-y-3">
                        <li v-for="item in info.page.data.most_comment.data" :key="item.title + item.id">
                            <DCardMain :info="item" />
                        </li>
                    </ul>
                </template>
                <ButtonLoadMore
                    v-if="pagination[getType].show"
                    class="mt-4"
                    :handler="loadMore"
                    :loading="pagination[getType].loading"
                />
            </main>

            <aside class="coupons-index-page__sidebar">
                <LayoutDescription
                    v-if="info.page.content"
                    model="secondary"
                    :info="{
                        title: $lang.pages.ofertas.description,
                        content: info && info.page ? info.page.content : '',
                    }"
                />
                <LayoutGallery
                    icon-color="primary"
                    icon-name="shop"
                    :path="$lang.routes.brands"
                    :title="$lang.pages.ofertas.popular_brands"
                    model="secondary"
                    :info="info.page.data.featured_stores"
                />
                <!-- <SidebarBanners :banner-pos="[1, 2]" /> -->
            </aside>
        </section>
    </div>
</template>

<script lang="ts" setup>
import { useAuthStore } from '~/store/auth'

import { replacePlaceholders } from '~/util/textFunction'
import type { Api } from '~~/global'

import { useDateFunctions } from '~/composables/DateFunctions'

const { currentDate } = useDateFunctions()

const { $lang } = useNuxtApp()
const { SessionToken } = useAuthStore()
const { buildHeaders, baseURL, endpoints } = useApiConfig()

const getCoupons = (page?: number) =>
    $fetch<Api.Responses.Pages.Offers>(endpoints.pages.offers.path, {
        headers: buildHeaders(SessionToken),
        baseURL,
        method: 'GET',
        params: {
            page,
        },
    })

const { data: responseData, error } = await useAsyncData(
    'coupons-index-page',
    async () => await getCoupons().catch((e) => e.data),
)

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

let info = responseData.value

const breadcrumbs = computed(() => {
    return [
        {
            path: '/',
            title: $lang.pages.ofertas.home,
        },
        {
            title: $lang.pages.ofertas.offers,
        },
    ]
})

const pagination = reactive({
    popular: {
        loading: false,
        show: true,
    },
    latest: {
        loading: false,
        show: true,
    },
    most_comment: {
        loading: false,
        show: true,
    },
})

const tabsData = reactive({
    show: 0,
    tabs: [
        { title: $lang.pages.ofertas.populars },
        { title: $lang.pages.ofertas.news },
        { title: $lang.pages.ofertas.commented },
    ],
})

const getType = computed(() => {
    return tabsData.show === 0 ? 'popular' : tabsData.show === 1 ? 'latest' : 'most_comment'
})

const loadMore = () => {
    pagination[getType.value].loading = true

    let next = 0

    const copyInfo = JSON.parse(JSON.stringify(info))

    if (tabsData.show === 0) {
        // Popular
        next = copyInfo.page.data.popular.meta.current_page + 1
        // Nuevos
    } else if (tabsData.show === 1) {
        next = copyInfo.page.data.latest.meta.current_page + 1
        // Comentados
    } else {
        next = copyInfo.page.data.most_comment.meta.current_page + 1
    }

    getCoupons(next)
        .then((res) => {
            if (tabsData.show === 0) {
                // Populares
                copyInfo.page.data.popular.data.push(...res.page.data.popular.data)
                copyInfo.page.data.popular.meta = res.page.data.popular.meta
            } else if (tabsData.show === 1) {
                // Nuevos
                copyInfo.page.data.latest.data.push(...res.page.data.latest.data)
                copyInfo.page.data.latest.meta = res.page.data.latest.meta
            } else {
                // Comentados
                copyInfo.page.data.most_comment.data.push(...res.page.data.most_comment.data)
                copyInfo.page.data.most_comment.meta = res.page.data.most_comment.meta
            }

            info = copyInfo

            pagination[getType.value] = {
                loading: false,
                show: next < info.page.data[getType.value].meta.last_page,
            }
        })
        .catch((err: any) => {
            console.error(err)
            pagination[getType.value].loading = false
        })
}
const currentDates = currentDate()
const {
    public: { origin, siteName },
} = useRuntimeConfig()

const [title, description] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':day', String(currentDates.day) || ''],
        [':year', String(currentDates.year) || ''],
        [':site', siteName || ''],
    ],
    [responseData.value.page.title || '', responseData.value.page.description || ''],
)

const Route = useRoute()

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.coupons-index-page {
    @apply container;
    &__slider {
        @apply container min-h-[287px] xs:min-h-[287px] lg:min-h-[331px];
    }

    &__featured-brands {
        @apply mt-5 space-y-3;
        h3 {
            @apply text-xl font-semibold text-gray-800;
            span {
                @apply font-semibold;
            }
        }
    }
    &__content {
        @apply mb-3 pt-6 xl:flex;
    }
    &__main {
        @apply gap-y-6 xl:order-2 xl:flex-grow;
    }

    &__sidebar {
        @apply mt-4 space-y-5 lg:order-1 xl:mr-5 xl:mt-0 xl:w-80 xl:flex-none;
    }
}
</style>
